import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { changeForgottenPassword } from "../services/auth";
import { z } from "zod";
import { resetPasswordInputSchema } from "../zod/auth";
import { useToast } from "../hooks/useToast";

type ResetPasswordSchemaType = z.infer<typeof resetPasswordInputSchema>;
type ResetPasswordSchemaError = z.typeToFlattenedError<ResetPasswordSchemaType>["fieldErrors"];
export function ResetPassword() {
  const [query] = useSearchParams();
  const { toast, setToast } = useToast();
  const [errors, setErrors] = useState<ResetPasswordSchemaError>({});
  const navigate = useNavigate();
  const {
    isMutating: isLoading,
    trigger: triggerChangePassword,
  } = useSWRMutation(
    "confirm_password",
    async (
      _,
      {
        arg,
      }: {
        arg: {
          token: string;
          user_id: string;
          password: string;
        };
      }
    ) => {
      const redirect = query.get("redirect");
      await changeForgottenPassword({
        body: { password: arg.password },
        query: { token: arg.token, user_id: arg.user_id },
      });
      navigate(redirect ?? "/login");
    },
    {
      throwOnError: false,
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Wrong inputs provided";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const user_id = query.get("user_id");
    const token = query.get("token");
    const data = new FormData(event.currentTarget);
    const password = data.get("password")?.toString() ?? "";
    const confirmPassword = data.get("confirmPassword")?.toString() ?? "";

    if (!user_id || !token) {
      setToast({
        message: "Missing query parameters",
        open: true,
        severety: "error",
      });
      return;
    }
    const parse = resetPasswordInputSchema.safeParse({
      password,
      confirmPassword,
    });
    if (!parse.success) {
      setErrors(parse.error.flatten().fieldErrors);
      return;
    }
    setErrors({});
    await triggerChangePassword({ ...parse.data, token, user_id });
  };

  return (
    <Box className="h-screen flex justify-center items-center pb-5">
      <Container className="max-w-xl py-4 bg-white shadow-xl border-4 border-gray-200 rounded-2xl flex flex-col justify-center items-center">
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Write your new password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.password?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              fullWidth
              name="confirmPassword"
              label="Confrim Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.confirmPassword?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Change my password
          </Button>
        </Box>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
