import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./utils/constants";
import {
  // StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { ToastProvider } from "./hooks/useToast";
const tagManagerArgs: TagManagerArgs = {
  gtmId: "GTM-MLBHVRZP",
  dataLayer: {},
};
TagManager.initialize(tagManagerArgs);
const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  typography: {
    fontFamily: "century-gothic, sans-serif", // Use your custom font and fallbacks
  },
  palette: {
    primary: {
      main: "#000000",
    },
  },
});
root.render(
  <ThemeProvider theme={theme}>
    <ToastProvider>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </GoogleOAuthProvider>
    </ToastProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
