import {
  AccountCircle,
  AddCardOutlined,
  AutoStoriesOutlined,
  ExitToAppOutlined,
  ExpandMore,
  FolderOpenOutlined,
  HelpOutline,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Chip,
  Container,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
  Card,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Drawer,
  Skeleton,
  Box,
  Tooltip,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useAuthContext } from "../hooks/useAuth";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";
import { getAllProject } from "../services/project";
import { useNavigate, useParams } from "react-router-dom";
import { BUILD_DATE } from "../utils/constants";
import { StyledAvatar } from "./Avatar";
import { useState } from "react";
const skelton = [1, 2, 34, 5, 6, 7, 7, 8, 8, 9];

const BuildDate = () => {
  return (
    <Typography
      className=""
      textAlign={"start"}
      fontSize={12}
      color={"text.secondary"}
    >
      {BUILD_DATE
        ? `build date: ${BUILD_DATE}`
        : `env: ${process.env.NODE_ENV}`}
    </Typography>
  );
};
export function SideMenu({ open }: { open: boolean }) {
  const { user, logout } = useAuthContext();
  const {
    isMutating: isLoggingOut,
    trigger: logoutTrigger,
    error: errorLogingOut,
    reset: resetLogOut,
  } = useSWRMutation("logout", () => logout(), { throwOnError: false });
  const {
    data: projects,
    isLoading: projectLoading,
  } = useSWR("get_all_projects", async () =>
    (await getAllProject()).sort((a, b) => b.id - a.id)
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const openHelp = () => {
    setIsHelpOpen(true);
  };
  const closeHelp = () => {
    setIsHelpOpen(false);
  };

  function handleLogout() {
    logoutTrigger();
  }

  function openSettingPage() {
    navigate("/setting");
  }
  function openManageSubscriptionPage() {
    navigate("/subscription");
  }
  return (
    // container
    <Drawer
      className="bg-transparent"
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          bgcolor: "transparent",
        },
      }}
    >
      <Container
        fixed
        className="w-96  bg-transparent z-10 w-max-[250px] px-6 p-5   h-screen  flex flex-col gap-5 justify-between"
      >
        <Card className="flex flex-col h-full bg-white border border-gray-300 p-4 gap-2 rounded-2xl ">
          <Box
            className={`${
              user?.organization.type === "INDIV" ? "hidden" : "flex"
            } flex-col justify-between items-center`}
          >
            <Tooltip title="Check out Public interviews" placement="right">
              <Button
                className="w-full flex gap-2 justify-start items-center"
                onClick={() => navigate("/library")}
                sx={{ textTransform: "none" }}
              >
                <AutoStoriesOutlined fontSize="large" />
                <h2 className="text-2xl font-semibold ">Library</h2>
              </Button>
            </Tooltip>
            <Divider orientation="horizontal" flexItem />
          </Box>
          <Box className="flex flex-row justify-between items-center ">
            <Box className="flex gap-2 pl-2">
              <FolderOpenOutlined fontSize="large" color="primary" />
              <Typography className="text-2xl font-semibold text-black ">
                Projects
              </Typography>
            </Box>
            <Chip
              onClick={() =>
                navigate({
                  pathname: "/createProject",
                })
              }
              label="New"
              icon={<Add />}
              variant="filled"
              clickable
              color="secondary"
            />
          </Box>

          <List className="overflow-auto grow scroll-smooth scrollbar-thumb-rounded-lg scrollbar-thumb-gray-500 scrollbar-track-gray-100 scrollbar-thin">
            {projectLoading
              ? skelton.map((_, index) => (
                  <Box
                    key={`skeleton-project-${index}`}
                    className="flex gap-3 p-2"
                  >
                    <Skeleton variant="rounded">
                      <Avatar />
                    </Skeleton>
                    <Skeleton width="100%">
                      <Typography>.</Typography>
                    </Skeleton>
                  </Box>
                ))
              : projects?.map((val) => (
                  <ProjectItem
                    key={`project-${val.id}`}
                    isSelected={!!id && id === val.id.toString()}
                    projectName={val.title}
                    onClick={() => navigate(`/p/${val.id}`)}
                  />
                ))}
            {/* <ProjectItem projectName="Software Auto" />
            <ProjectItem projectName="tips to avoid fake job scams" />
            <ProjectItem projectName="art collaborations of 2023" />
            <ProjectItem projectName="what is artificial snow made of" />
            <ProjectItem projectName="bioacoustic monitoring of human impact" />
            <ProjectItem projectName="approaches to skill development" />
            <ProjectItem projectName="how can embracing a growth mindset improve your life" />
            <ProjectItem projectName="edible printers" />
            <ProjectItem projectName="the latest developments in gaming technology" />
            <ProjectItem projectName="how does the Beaufort scale compare to other wind measurement scales" />
            <ProjectItem projectName="do product reviews really help consumers" />
            <ProjectItem projectName="do people tow icebergs to get water" />
            <ProjectItem projectName="is happiness a choice" /> */}
          </List>
          <Box className="flex flex-col w-full gap-2 border-2 border-gray-300 rounded-xl py-2">
            <Button
              className="py-2 w-full  justify-start  border-0 hover:bg-gray-200 rounded-xl"
              variant="outlined"
              onClick={openHelp}
              startIcon={<HelpOutline />}
            >
              Help & Support
            </Button>
            <Button
              className="py-2 w-full  justify-start  border-0 hover:bg-gray-200 rounded-xl"
              variant="outlined"
              onClick={openSettingPage}
              startIcon={<SettingsOutlined />}
            >
              Settings
            </Button>
            <Button
              className="py-2 w-full  justify-start  border-0 hover:bg-gray-200 rounded-xl"
              variant="outlined"
              onClick={openManageSubscriptionPage}
              startIcon={<AddCardOutlined />}
            >
              manage subscription
            </Button>
            <Button
              className="py-2 w-full  justify-start  border-0 hover:bg-gray-200 rounded-xl hover:text-red-500"
              variant="outlined"
              onClick={handleLogout}
              startIcon={<ExitToAppOutlined />}
            >
              Logout
            </Button>
            <Box className=" p-1 flex justify-start items-center gap-2  rounded-xl cursor-default">
              <Avatar
                variant="square"
                className=" bg-transparent  text-black "
                children={<AccountCircle fontSize="large" />}
              />
              <Typography variant="h6" className="font-semibold">
                {user?.profile
                  ? `${user?.profile.first_name} ${user?.profile.last_name}`
                  : user?.email}
              </Typography>
            </Box>
          </Box>
        </Card>
        <BuildDate />
        {/* loading UI */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          // open={true}
          open={isLoggingOut}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={!!errorLogingOut}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => resetLogOut()}
        >
          <Alert
            onClose={() => resetLogOut()}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
        <Dialog maxWidth={"lg"} fullWidth open={isHelpOpen} onClose={closeHelp}>
          <HelpDialog onClose={closeHelp} />
        </Dialog>
      </Container>
    </Drawer>
  );
}

const HelpDialog = ({ onClose }: { onClose: () => any }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const youtubeFrame = (
    <iframe
      className="w-4/5 h-[400px]"
      src="https://www.youtube.com/embed/2XY5jOASR-g?si=UcO1jKHIIDn22cFz"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    ></iframe>
  );

  const sections = [
    { text: "Create Project", component: youtubeFrame },
    { text: "Upload Interviews", component: youtubeFrame },
    { text: "Transcribe Interviews", component: youtubeFrame },
    { text: "Manage Insights", component: youtubeFrame },
    { text: "Use Assistant", component: youtubeFrame },
    { text: "Collaborate", component: youtubeFrame },
  ];
  // <Box className="w-full">
  //   <Accordion className="w-full">
  //     <AccordionSummary
  //       expandIcon={<ExpandMore />}
  //       aria-controls="panel1bh-content"
  //       id="panel1bh-header"
  //       className="flex justify-center items-center border-b-2 border-gray-400 border-solid"
  //     >
  //       <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
  //         Get Started
  //       </Typography>
  //     </AccordionSummary>
  //     <AccordionDetails className="flex justify-center items-center">
  //       <iframe
  //         width="800"
  //         height="600"
  //         src="https://www.youtube.com/embed/fIrMz_23RRU?si=UcO1jKHIIDn22cFz"
  //         title="YouTube video player"
  //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //       ></iframe>
  //     </AccordionDetails>
  //   </Accordion>
  //   <Accordion className="w-full">
  //     <AccordionSummary
  //       expandIcon={<ExpandMore />}
  //       aria-controls="panel1bh-content"
  //       id="panel1bh-header"
  //       className="flex justify-center items-center border-b-2 border-gray-400 border-solid"
  //     >
  //       <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
  //         Learn about features
  //       </Typography>
  //     </AccordionSummary>
  //     <AccordionDetails className="flex justify-center items-start">
  //       <Box className="flex gap-2">
  //         <Box className="w-full flex flex-col justify-start items-start ">
  //           {sections.map((val, index) => (
  //             <Button
  //               className={`${
  //                 index === selectedIndex ? "font-bold" : "font-medium"
  //               } hover:font-bold transition-all ease-linear`}
  //               onClick={() => setSelectedIndex(index)}
  //             >
  //               {val.text}
  //             </Button>
  //           ))}
  //         </Box>
  //       </Box>
  //       <Box className="w-4/5 grow flex justify-center">
  //         {sections[selectedIndex].component}
  //       </Box>
  //     </AccordionDetails>
  //   </Accordion>
  //   <Accordion className="w-full">
  //     <AccordionSummary
  //       expandIcon={<ExpandMore />}
  //       aria-controls="panel1bh-content"
  //       id="panel1bh-header"
  //       className="flex justify-center items-center border-b-2 border-gray-400 border-solid"
  //     >
  //       <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
  //         Contact Support
  //       </Typography>
  //     </AccordionSummary>
  //     <AccordionDetails className="flex justify-center items-center">
  //       <Typography>
  //         👋 Need a hand? We're all ears! 🎧
  //         <br />
  //         <br /> At AI_xperts, we want you to have the best possible
  //         experience. If you've got questions, concerns, or just want to
  //         chat about our features, don't hesitate to drop us a line. We're
  //         here to help make your journey with us absolutely stellar! 🌟
  //         <br />
  //         <br /> Reach out anytime — we love hearing from you!
  //         <br />
  //         <br />
  //         <a className="text-blue-600" href="mailto:support@ai-xperts.io">
  //           support@ai-xperts.io
  //         </a>
  //         <br />
  //         <br /> <strong>Cheers, The AI_xperts Team 🚀</strong>
  //       </Typography>
  //     </AccordionDetails>
  //   </Accordion>
  // </Box>
  return (
    <Box className="w-full flex flex-col p-4 gap-2 px-8">
      <Typography fontSize={24} fontWeight={700}>
        Help & Support
      </Typography>
      <Typography>
        This{" "}
        <strong>
          brief tutorial video will help you get started in no time 🚀
        </strong>
      </Typography>
      <iframe
        width="800"
        height="480"
        className="mx-auto"
        src="https://www.youtube.com/embed/2XY5jOASR-g"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>{" "}
      <Typography>
        <strong>Need additional support?</strong> We're all ears! 🎧
        <br />
        <br /> At AI_xperts, we want you to have the best possible experience.
        If you've got questions, concerns, or just want to chat about our
        features, don't hesitate to drop us a line. We're here to help make your
        journey with us absolutely stellar! 🌟
        <br />
        <br /> Reach out anytime — we love hearing from you!
        <br />
        <br />
        <a className="text-blue-600" href="mailto:support@ai-xperts.io">
          support@ai-xperts.io
        </a>
        <br />
        <br /> <strong>Cheers, The AI_xperts Team 🚀</strong>
      </Typography>
    </Box>
  );
};

const ProjectItem = ({
  projectName,
  isSelected = false,
  onClick,
}: {
  projectName: string;
  isSelected?: boolean;
  onClick?: () => any;
}) => {
  return (
    <ListItemButton
      className={`${
        isSelected ? "bg-gray-300" : ""
      } hover:bg-gray-300 rounded-lg`}
      onClick={onClick}
    >
      <ListItemAvatar>
        <StyledAvatar
          name={`${projectName
            .split(" ")
            .reduce((a, b) => a + b[0], "")
            .slice(0, 2)}`}
        />
      </ListItemAvatar>
      <ListItemText
        primary={projectName}
        className="!font-bold"
        primaryTypographyProps={{
          fontSize: 15,
          fontWeight: "700",
          lineHeight: "20px",
          mb: "2px",
        }}
      />
    </ListItemButton>
  );
};
