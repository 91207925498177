import { useAuthContext } from "../hooks/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LoginForm } from "../components/login/LoginForm";
import { SignUpForm } from "../components/login/SignUpForm";
import { ForgetPasswordForm } from "../components/login/ForgetPasswordForm";
export type FormIndexProps = {
  changeForm: (index: number) => any;
};
export const LOGIN_PAGE_FORMS = {
  LOGIN: 0,
  SIGNUP: 1,
  FORGETPASSWORD: 2,
} as const;
export function LoginPage() {
  const { isAuth } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const [formIndex, setFormIndex] = useState<number>(
    view === "signup" ? LOGIN_PAGE_FORMS.SIGNUP : LOGIN_PAGE_FORMS.LOGIN
  );
  useEffect(() => {
    if (isAuth) navigate("/");
    // eslint-disable-next-line
  }, [isAuth]);
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          // backgroundImage: "url('/login-illustration.svg')",
          backgroundRepeat: "no-repeat",
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box className="w-full h-full flex flex-col justify-center items-center gap-12 select-none">
          <Typography fontWeight={600} fontSize={52}>
            AI_xperts
          </Typography>
          <Typography fontSize={31} fontWeight={500}>
            your AI copilot for expert interviews
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {formIndex === LOGIN_PAGE_FORMS.LOGIN && (
          <LoginForm
            changeForm={(index) => {
              setFormIndex(index);
            }}
          />
        )}
        {formIndex === LOGIN_PAGE_FORMS.SIGNUP && (
          <SignUpForm
            changeForm={(index) => {
              setFormIndex(index);
            }}
          />
        )}
        {formIndex === LOGIN_PAGE_FORMS.FORGETPASSWORD && (
          // TODO: to be changed
          <ForgetPasswordForm
            changeForm={(index) => {
              setFormIndex(index);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
