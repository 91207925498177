// Generated by ts-to-zod
import { z } from "zod";

export const getQuestionnaireInputSchema = z.object({
  industry: z
    .string()
    .trim()
    .min(2, "Please provide a valid industry name (at least 2 characters)")
    .max(56, "Please provide a valid industry name (less than 56 characters)"),
  angles: z
    .string()
    .trim()
    .min(2, "Please provide valid angles/dimensions (at least 2 characters)")
    .max(
      256,
      "Please provide valid angles/dimensions (less than 256 characters)"
    ),
  geography: z
    .string()
    .trim()
    .max(256, "Please provide valid geography (less than 256 characters)")
    .optional()
    .default(""),
  additional_background: z
    .string()
    .trim()
    .max(
      512,
      "Please provide valid additional background (less than 512 characters)"
    )
    .optional()
    .default(""),
  additional_file_background: z.string().trim().optional().default(""),
});

export const createProjectInputSchema = getQuestionnaireInputSchema.and(
  z.object({
    questions: z.array(
      z.object({
        angle: z.string().trim(),
        question: z.string().trim(),
      })
    ),
  })
);

export const addQuestionToProjectInputSchema = z.object({
  angle: z
    .string()
    .trim()
    .min(2, "Please provide valid angles/dimensions (at least 2 characters)")
    .max(
      56,
      "Please provide valid angles/dimensions (less than 56 characters)"
    ),

  question: z
    .string()
    .trim()
    .min(10, "Please provide valid question (at least 10 characters)")
    .max(512, "Please provide valid question (less than 512 characters)"),
});

export const updateProjectInputSchema = z.object({
  companies: z
    .array(
      z
        .string()
        .trim()
        .min(2)
        .max(256)
        .refine((value) => !/[^\w\s']/.test(value), {
          message:
            "Please note these formatting guidelines for custom vocabulary: Remove all punctuation except apostrophes",
        })
        .refine((value) => !/\d/.test(value), {
          message:
            'Please note these formatting guidelines for custom vocabulary: Each custom vocabulary needs to be in its spoken form and must not contain any digits (for example, "iphone seven" instead of "iphone 7")',
        })
        .refine((value) => value.trim().split(/\s+/).length <= 6, {
          message:
            "Please note these formatting guidelines for custom vocabulary: Each vocabulary item can contain only up to 6 word",
        })
    )

    .max(500, "You can't have more than 500 companies")
    .optional(),
  abbreviations: z
    .array(
      z
        .string()
        .trim()
        .min(2)
        .max(256)
        .refine((value) => !/[^\w\s']/.test(value), {
          message:
            "Please note these formatting guidelines for custom vocabulary: Remove all punctuation except apostrophes",
        })
        .refine((value) => !/\d/.test(value), {
          message:
            'Please note these formatting guidelines for custom vocabulary: Each custom vocabulary needs to be in its spoken form and must not contain any digits (for example, "iphone seven" instead of "iphone 7")',
        })
        .refine((value) => value.trim().split(/\s+/).length <= 6, {
          message:
            "Please note these formatting guidelines for custom vocabulary: Each vocabulary item can contain only up to 6 word",
        })
    )
    .max(500, "You can't have more than 500 abbreviations")
    .optional(),
});
