import { Mic } from "@mui/icons-material";
import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import {
  InterviewWithCosmo,
  getAllInterviewsByProject,
  uploadInterviewFile,
  UploadInterviewResponse,
} from "../../services/interview";
import { getPendingInterviewTasks } from "../../services/interviewTasks";
import { FileInputDialog } from "../input/FileUpload";
import { InterviewDetails } from "./InterviewDetails";
import { SuggestionBox } from "./SuggestionBox";
import { AudioRecorder } from "../input/AudioRecorder";
import TagManager from "react-gtm-module";
import { LanguageCode } from "../../utils/languageCodes";
import { useProject } from "../../hooks/useProject";
import { useToast } from "../../hooks/useToast";
import { LoadingScreen } from "../../pages/Project";
import { useAuthContext } from "../../hooks/useAuth";
import { usePlusPlan } from "../../hooks/usePlusPlan";

export const InterviewsTab = ({}: {}) => {
  const [limitReachedOpen, setLimitReachedOpen] = useState(false);
  const { user, canUpload } = useAuthContext();
  const { data: project, mutate: mutateProject } = useProject();
  const [pendingTasksCount, setPendingTasksCount] = useState(0);
  const { data: interviews, mutate: mutateAllInterviews } = useSWR(
    project ? [`api/interview/getall/`, project.id] : null,
    ([, projectId]) =>
      getAllInterviewsByProject({ projectId: projectId.toString() })
  );
  const [isRecordingDialogOpen, setIsRecordingDialogOpen] = useState(false);
  const [isSuggestionBoxOpen, setIsSuggestionBoxOpen] = useState(false);

  const { mutate: mutatePendingTasks } = useSWR(
    project ? [`api/interviewTask/getPending`, project.id] : null,
    ([, projectId]) => getPendingInterviewTasks({ projectId }),
    {
      onSuccess(data) {
        if (data.length < pendingTasksCount) {
          // it means new interview got added
          // or task failed
          // either way try to update project
          mutateProject();
          mutate("get_all_project");
          mutateAllInterviews();
        }
        setPendingTasksCount(data.length);
      },
      refreshInterval: 10 * 1000, // 10 secs
    }
  );

  // when file is uploaded succesfuly
  // and the file start being processed in backend background
  const onUploadCompleted = () => {
    mutatePendingTasks();
  };
  // on upload file  failure
  const onUploadFailure = () => {};
  const openSuggestionBox = () => setIsSuggestionBoxOpen(true);
  const closeSuggestionBox = () => setIsSuggestionBoxOpen(false);

  if (!interviews || !project) return <LoadingScreen />;

  return (
    <Box className="w-full  flex flex-col h-full justify-between pl-4">
      <Box className="flex flex-col  justify-start  gap-2 overflow-y-auto overflow-x-hidden  w-full h-[80%] grow shrink-0 border-b-4 border-transparent border-solid pb-2 ">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          {interviews
            .sort((a, b) => a.id - b.id)
            .map((interview) => (
              <Grid item key={`interview-${interview.id}`}>
                <InterviewCard interview={interview} />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box className="flex flex-col h-[20%] max-h-[20%] gap-2 justify-end">
        {user?.organization.type === "COMP" && (
          <Typography
            onClick={openSuggestionBox}
            className="font-semibold h-1/5 text-start px-2 underline hover:cursor-pointer"
          >
            View suggested interviews
          </Typography>
        )}
        <Box className="relative w-full  h-2/5 grow">
          <Badge
            color="secondary"
            badgeContent={pendingTasksCount}
            className="absolute right-6 top-6 z-40 animate-pulse"
          />
          <UploadFileDialog
            onUploadCompleted={onUploadCompleted}
            onUploadFailure={onUploadFailure}
          />
        </Box>
        <Box className="w-full   flex flex-col justify-center h-2/5 grow  ">
          <Box
            className="w-full h-full border-gray-400 hover:border-blue-400 min-h-[50px]  rounded bg-gray-100 border border-dashed transition flex flex-row justify-start items-center px-4 cursor-pointer"
            onClick={async () =>
              (await canUpload())
                ? setIsRecordingDialogOpen(true)
                : setLimitReachedOpen(true)
            }
          >
            <Mic />
            <Typography fontSize={18} paddingX={2}>
              Record interview
            </Typography>
          </Box>
        </Box>
      </Box>
      <Dialog maxWidth={"sm"} open={isRecordingDialogOpen}>
        <AudioRecorder onClose={() => setIsRecordingDialogOpen(false)} />
      </Dialog>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={isSuggestionBoxOpen}
        onClose={closeSuggestionBox}
      >
        <SuggestionBox
          suggestedInterview={project.suggested_intreviews}
          onGenerateInsigts={() => {
            mutateProject();
            mutatePendingTasks();
            closeSuggestionBox();
            // TODO: filter suggested interviews
          }}
          onClose={closeSuggestionBox}
        />
      </Dialog>
      <LimitReachedDialog
        open={limitReachedOpen}
        onClose={() => setLimitReachedOpen(false)}
      />
    </Box>
  );
};

function InterviewCard({ interview }: { interview: InterviewWithCosmo }) {
  const [open, setOpen] = useState(false);
  const [interviewDetailEditMode, setInterviewDetailEditMode] = useState(0);
  const { data: project } = useProject();

  if (!project) return null;
  const { isManager, id: projectId } = project;
  return (
    <Box className="border  p-3 gap-2 rounded-2xl flex flex-col  w-72 h-full ">
      <Typography fontWeight={700} className="mb-4">
        {interview.cosmo_doc.metadata.date}
      </Typography>
      <Typography
        fontWeight={700}
        className="mb-4"
      >{`${interview.cosmo_doc.metadata.job_position} @ ${interview.cosmo_doc.metadata.company}`}</Typography>

      <Stack className="mb-4 mt-auto">
        <Divider />
        <Typography>{interview.cosmo_doc.metadata.tags.join(", ")}</Typography>
        <Divider />
      </Stack>
      <Box className="mt-auto">
        <Button
          color="secondary"
          variant="contained"
          className="normal-case rounded-full"
          onClick={() => setOpen(true)}
        >
          Details
        </Button>
      </Box>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={!!open}
        onClose={() => {
          if (interviewDetailEditMode === 0) setOpen(false);
        }}
      >
        <DialogContent>
          <InterviewDetails
            interview={interview.cosmo_doc}
            enableDeletingInterviews={isManager}
            onDelete={() => {
              mutate([`api/interview/getall/`, projectId]);
            }}
            onEditMetadata={() => {
              mutate([`api/interview/getall/`, projectId]);
            }}
            enableInterviewEdit={isManager && interview.project === projectId}
            onEditInterview={async (interview) => {
              mutate("api/interview/getall/");
            }}
            onEnterEditMode={() =>
              setInterviewDetailEditMode(interviewDetailEditMode + 1)
            }
            onExitEditMode={() =>
              setInterviewDetailEditMode(interviewDetailEditMode - 1)
            }
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export function UploadFileDialog({
  onUploadCompleted,
  onUploadFailure,
}: {
  onUploadCompleted: (response: UploadInterviewResponse) => any;
  onUploadFailure: (message: string) => any;
}) {
  const { user } = useAuthContext();
  const { canUpload } = useAuthContext();
  const { data: project } = useProject();
  const [participants, setParticipants] = useState<number>(0);
  const [languageCode, setLanguageCode] = useState<
    keyof typeof LanguageCode | "N/A"
  >("N/A");
  const [files, setFiles] = useState<File[]>([]);
  const [settingParticipants, setSeettingParticipants] = useState(false);
  const [limitReachedOpen, setLimitReachedOpen] = useState(false);
  const { setToast } = useToast();
  const {
    isMutating: isUploadingInterview,
    trigger: triggerUploadInterview,
  } = useSWRMutation(
    () => "upload_audio",
    async (_, { arg }: { arg: File[] }) => {
      if (!project) throw new Error("There is no selected project");
      const files = arg;
      const formData = new FormData();
      for (const file of files) {
        formData.append("files", file);
      }
      formData.append("projectId", project.id.toString());
      participants > 0 &&
        formData.append("participants", participants.toString());
      languageCode !== "N/A" && formData.append("languageCode", languageCode);
      const resp = await uploadInterviewFile({
        formData,
        onUploadProgress: (params) => {},
      });

      return resp;
    },
    {
      throwOnError: false,
      onSuccess(resp) {
        TagManager.dataLayer({
          dataLayer: { event: "upload_interview" },
        });
        console.log(resp);
        if (resp.limitReached) {
          setLimitReachedOpen(true);
          return;
        }
        setToast({ open: true, severety: "success", message: resp.message });
        onUploadCompleted(resp);
      },
      onError(err) {
        const resp = err.response?.data;
        if (resp.limitReached) {
          setLimitReachedOpen(true);
          return;
        }

        setToast({ open: true, severety: "error", message: resp?.message });
        onUploadFailure(resp.message ?? "");
      },
    }
  );

  if (!project) return null;
  return (
    <Box className="flex flex-col justify-center gap-2  w-full h-full min-w-[200px]">
      <FileInputDialog
        fileTypes={["pdf", "txt", "vtt", "docx", "mp3", "wav", "m4a", "mp4"]}
        maximumSize={10.24e8}
        uploadMessage="Upload interview files"
        onSelectFiles={async (files) => {
          if (!(await canUpload())) {
            setLimitReachedOpen(true);
            return;
          }
          setFiles(files);
          const thereIsAudioFile = files.some((file) =>
            ["wav", "mp3", "m4a", "mp4"].includes(
              file.name.split(".").at(-1) ?? " "
            )
          );
          if (thereIsAudioFile) setSeettingParticipants(true);
          else await triggerUploadInterview(files);

          return "Selected files successfuly";
        }}
        maxLength={user?.subscription?.status === "trialing" ? 1 : 10}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadingInterview}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog fullWidth maxWidth={"sm"} open={settingParticipants}>
        <DialogContent className="flex flex-col">
          <Typography
            variant="h6"
            children="Here you can provide additional information to improve your transcript quality."
          />
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="label">Number of participants:</InputLabel>
            <Select
              value={participants}
              onChange={(e) => setParticipants(Number(e.target.value))}
              displayEmpty
              labelId="label"
              label="Number of participants:"
            >
              <MenuItem value={0}>
                <em>N/A</em>
              </MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={-1}>7+</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="label">Main language:</InputLabel>
            <Select
              value={languageCode}
              onChange={(e) => setLanguageCode(e.target.value as any)}
              displayEmpty
              labelId="label"
              label="Main language:"
            >
              <MenuItem key={"N/A"} value={"N/A"}>
                <em>N/A</em>
              </MenuItem>
              {Object.entries(LanguageCode).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className="flex w-full justify-center">
            <Button
              variant="contained"
              color="secondary"
              className="rounded-full mx-8"
              onClick={() => {
                setSeettingParticipants(false);
                triggerUploadInterview(files);
              }}
            >
              Upload Interview
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <LimitReachedDialog
        open={limitReachedOpen}
        onClose={() => setLimitReachedOpen(false)}
      />
    </Box>
  );
}

const LimitReachedDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => any;
}) => {
  const {
    plusPlanePriceId,
    redirectToPaymentLink,
    isGeneratingLink,
  } = usePlusPlan();
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogContent className="flex flex-col relative">
        <strong>Keep the magic going with your Plus plan</strong>
        <br />
        Happy to see you’re enjoying AI_xperts 😊
        <br />
        <br />
        You’ve reached the interview limit of your free trial, but no worries,
        you can keep the magic going by jumping on our Plus plan🚀
        <br />
        <br />
        <span>
          Got questions? Curious about promos? Hit us up at
          <br />
          <a href="mailto:sales@ai-xperts.io" className="text-blue-600">
            sales@ai-xperts.io
          </a>{" "}
          — we're here to help!
        </span>
        <br />
        Stay awesome!
        <br />
        Your friends at AI_xperts 🌟
        <Stack direction={"column"} spacing={3} className="px-4 w-full ">
          <Stack direction={"row"} spacing={1} justifyContent={"end"}>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!plusPlanePriceId || isGeneratingLink}
              onClick={() => redirectToPaymentLink()}
            >
              Upgrade to plus
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
