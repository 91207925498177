import { axios } from "../utils/axios";

export type InterviewTask = {
  id: number;
  title: string;
  description: string;
  state: "PENDING" | "SUCCES" | "FAILED";
  created_at: string;
  updated_at: string;
  filepath: string;
  user: number;
  project: number;
  interview: number | null;
};
export async function getPendingInterviewTasks({
  projectId,
}: {
  projectId: string | number;
}): Promise<InterviewTask[]> {
  return (
    await axios.get(`/api/interviewTask/getPending/`, {
      params: {
        projectId,
      },
    })
  ).data;
}
