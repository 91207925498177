import { AxiosProgressEvent } from "axios";
import { axios } from "../utils/axios";
import { CosmoInterview, InterviewType } from "./project";

export type UploadInterviewResponse = {
  limitReached: boolean;
  message: string;
};
export async function uploadInterviewFile({
  formData,
  onUploadProgress,
}: {
  formData: FormData;
  onUploadProgress: (params: AxiosProgressEvent) => any;
}): Promise<UploadInterviewResponse> {
  return (
    await axios.post("/api/interview/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
  ).data;
}

export type InterviewWithCosmo = {
  id: number;
  cosmo_doc: CosmoInterview;
  title: string;
  type: InterviewType;
  tags: any[];
  created_at: string;
  updated_at: string;
  project: number;
};

export async function getAllInterviewsByProject({
  projectId,
}: {
  projectId: string;
}): Promise<InterviewWithCosmo[]> {
  return (await axios.get("/api/interview/getAll/", { params: { projectId } }))
    .data;
}

export type Pagination<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export async function getAllPublicInterviews({
  page,
  per_page,
}: {
  page?: string | number;
  per_page?: string | number;
}): Promise<Pagination<CosmoInterview>> {
  return (
    await axios.get("/api/interview/getAllPublic/", {
      params: {
        page,
        per_page,
      },
    })
  ).data;
}

export async function getInterviewContent({
  interviewId,
}: {
  interviewId: string;
}): Promise<string> {
  return (
    await axios.get("/api/interview/getContent/", { params: { interviewId } })
  ).data;
}

export type UpdateInterviewArgs = {
  interviewId: number;
  date?: string;
  industry?: string;
  company?: string;
  job_position?: string;
  expert_name?: string;
  summary?: string;
  tags?: string[];
  content?: string;
  takeaways?: string;
  structured_summary?: string;
};

export async function updateInterviewMetadata(params: UpdateInterviewArgs) {
  return (await axios.put("/api/interview/update/", params)).data;
}

export async function deleteInterview({
  interviewId,
  projectId,
}: {
  interviewId: string | number;
  projectId: string | number;
}): Promise<string> {
  return (
    await axios.delete("/api/interview/delete/", {
      params: { interviewId, projectId },
    })
  ).data;
}
