import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { z } from "zod";
import {
  ProjectBackground,
  Questionnaire,
  createProject,
  getQuestionnaire,
  extractFileContent,
} from "../../services/project";
import {
  addQuestionToProjectInputSchema,
  createProjectInputSchema,
  getQuestionnaireInputSchema,
} from "../../zod/project";
import { mutate } from "swr";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { parse } from "best-effort-json-parser";
import { TextCell } from "../project/TextCell";
import { last } from "lodash";
import { PartialQuestion } from "../../services/project";
import { FileInputField } from "../input/FileUpload";
import { useToast } from "../../hooks/useToast";

type CreateProjectType = z.infer<typeof createProjectInputSchema>;
type GetQuestionnaireErrorType = z.typeToFlattenedError<CreateProjectType>["fieldErrors"];
type QuestionRowType = { id: number } & PartialQuestion;
export function CreateProjectForm() {
  const { setToast } = useToast();
  const [isQuestionnaireVisibile, setIsQuestionnaireVisible] = useState(false);
  const [background, setBackground] = useState<
    ProjectBackground & { additional_file_background: string }
  >({
    industry: "",
    angles: "",
    additional_background: "",
    geography: "",
    additional_file_background: "",
  });
  const [errors, setErrors] = useState<GetQuestionnaireErrorType>({});

  const navigate = useNavigate();
  const emptyQuestion = { id: 9999, angle: "", question: "" };
  const {
    trigger: triggerGetQuesstionnaire,
    isMutating: isQuestionnaireLoading,
  } = useSWRMutation(
    "get_questonnaire",
    async (
      _,
      {
        arg: { background },
      }: {
        arg: {
          background: ProjectBackground & {
            additional_file_background: string;
          };
        };
      }
    ) => {
      return getQuestionnaire({
        background,
        onDownloadProgress(progressEvent) {
          if (progressEvent.event.currentTarget.status !== 200) return;
          const { response } = progressEvent.event.currentTarget;
          if (!response) return;
          const { questions } = parse(response) as Questionnaire;
          if (!questions || !questions.length) return;
          setQuestions(
            questions
              .filter((partialQuestion) => partialQuestion.question)
              .map((partialQuestion, index) => {
                return {
                  ...partialQuestion,
                  id: index,
                };
              })
          );
        },
      });
    },
    {
      throwOnError: false,
      onSuccess(data) {
        const questions = data.questions.map((partialQuestion, index) => {
          return {
            ...partialQuestion,
            id: index,
          };
        });
        setQuestions([
          ...questions,
          { id: questions.length, angle: "", question: "" },
        ]);
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while generating your questionnare! please try again later.";
        setToast({
          severety: "error",
          message,
          open: true,
        });
      },
    }
  );
  const {
    trigger: triggerCreateProjct,
    isMutating: isProjectBeingCreated,
  } = useSWRMutation(
    "create_project",
    async (
      _,
      { arg }: { arg: ProjectBackground & { questions: PartialQuestion[] } }
    ) => {
      return createProject({
        ...arg,
      });
    },
    {
      throwOnError: false,
      onSuccess(data) {
        mutate("get_all_projects");
        navigate({
          pathname: `/p/${data.id}`,
          search: createSearchParams({ sideWindow: "INTERVIEWS" }).toString(),
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please while creating your project! Please try again.";

        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    trigger: triggerExtractFileContent,
    isMutating: isExtractingContent,
  } = useSWRMutation(
    "extract_file_content",
    async (
      _,
      {
        arg: { file, setProgress },
      }: { arg: { file: File; setProgress: (progress: number) => any } }
    ) => {
      const formData = new FormData();
      formData.append("file", file);
      return await extractFileContent({
        formData,
        onUploadProgress: (params) => {
          const { loaded, total } = params;
          const percentage = loaded / total!;

          setProgress(Math.round(100 * percentage));
        },
      });
    },
    {
      throwOnError: true,
      onSuccess(data) {
        setBackground((prev) => ({
          ...prev,
          additional_file_background: data,
        }));
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please while creating your project! Please try again.";

        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const questionnaireColumns: GridColDef<QuestionRowType>[] = [
    {
      field: "angle",
      headerName: "Angle",
      width: 200,
      editable: true,
      sortable: false,
      renderCell(params) {
        return params.row.angle && <TextCell value={params.row.angle} />;
      },
      // renderEditCell(params) {
      //   return <TextAreaEditCell {...params} />;
      // },
    },
    {
      field: "question",
      headerName: "Question",
      minWidth: 250,
      flex: 1,
      editable: true,
      sortable: false,

      renderCell(params) {
        return params.row.question && <TextCell value={params.row.question} />;
      },
      // renderEditCell(params) {
      //   return <TextAreaEditCell {...params} />;
      // },
    },
  ];

  const [questions, setQuestions] = useState<QuestionRowType[]>([]);
  const onCreateQuestionnaire = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const parse = getQuestionnaireInputSchema.safeParse(background);
    if (parse.success) {
      setIsQuestionnaireVisible(true);
      setErrors({});
      triggerGetQuesstionnaire({ background: parse.data });
      return;
    }
    setErrors(parse.error.flatten().fieldErrors);
  };
  const onCreateProject = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // remove empty message at the end
    const questionToBeAdded = questions
      .slice(0, -1)
      //filter the rows withi no questions following #303
      .filter((val) => val.question.trim() !== "");

    for (let index = 0; index < questionToBeAdded.length; index++) {
      const parse = addQuestionToProjectInputSchema.safeParse(
        questionToBeAdded[index]
      );
      if (!parse.success) {
        setToast({
          message: `${
            (parse.error.flatten().fieldErrors.angle?.at(0)
              ? "Missing Angle: Please provide an Angle for every Question to create the project."
              : undefined) ??
            parse.error.flatten().fieldErrors.question?.at(0) ??
            ""
          }`,
          open: true,
          severety: "error",
        });
        return;
      }
    }
    const parse = createProjectInputSchema.safeParse({
      ...background,
      questions: questionToBeAdded,
    });
    if (parse.success) {
      setErrors({});
      triggerCreateProjct({ ...parse.data });
      return;
    }
    console.log(parse.error.flatten().fieldErrors);
    setErrors(parse.error.flatten().fieldErrors);
  };
  const processRowUpdate = (
    newRow: QuestionRowType,
    oldRow: QuestionRowType
  ) => {
    function isQuestionEmpty(question: QuestionRowType) {
      return !question.angle.trim() && !question.question.trim();
    }
    function isQuestionLast(question: QuestionRowType) {
      const lastQuestion = last(questions);
      return !!lastQuestion && lastQuestion.id === question.id;
    }

    let newQuestions = questions;

    // if newRow is empty and not last we just remove it
    if (isQuestionEmpty(newRow) && !isQuestionLast(newRow)) {
      newQuestions = newQuestions.filter((val) => val.id !== newRow.id);
      setQuestions(newQuestions);
      return newRow;
    }
    //else we update anyway
    newQuestions = newQuestions.map((val) =>
      val.id === newRow.id ? newRow : val
    );
    //if we updated the last item
    //we also need to add new empty question
    if (isQuestionLast(newRow) && !isQuestionEmpty(newRow)) {
      newQuestions = [...newQuestions, { ...emptyQuestion, id: newRow.id + 1 }];
    }
    setQuestions(newQuestions);
    return newRow;
  };
  return (
    <Box className="p-4  w-full h-full flex flex-col  ">
      {/* First Prompt */}
      <Box className="w-full h-full flex flex-col gap-2 px-8 py-2 border border-gray-300 shadow-lg justify-start   bg-white overflow-y-scroll scroll-smooth scrollbar-thumb-rounded-lg scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thin ">
        <Typography variant="h4" fontWeight={700} textAlign={"start"}>
          New Project
        </Typography>
        <Typography variant="body1" fontWeight={600} textAlign={"start"}>
          To create a new project, please provide some background information to
          create an interview questionnaire. You can edit the questionnaire as
          needed before creating the project.
        </Typography>
        {isExtractingContent || isQuestionnaireLoading ? (
          <LinearProgress color="primary" className="h-[1px]" />
        ) : (
          <Divider className="text-black bg-black" />
        )}
        <Box
          className="w-full flex flex-col"
          component="form"
          onSubmit={onCreateQuestionnaire}
        >
          <FormControl fullWidth>
            <TextField
              margin="normal"
              // required
              id="industry"
              label="Industry"
              name="industry"
              type="text"
              className="m-1"
              value={background.industry}
              onChange={(ev) => {
                setBackground({ ...background, industry: ev.target.value });
                setErrors({ ...errors, industry: undefined });
              }}
              error={Boolean(errors.industry)}
              // disabled={isUpdating}
              autoComplete="off"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.industry?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              // required
              // fullWidth
              id="angles"
              label="Angles/Dimensions"
              name="angles"
              type="text"
              className="m-1"
              value={background.angles}
              onChange={(ev) => {
                setBackground({ ...background, angles: ev.target.value });
                setErrors({ ...errors, angles: undefined });
              }}
              error={Boolean(errors.angles)}
              autoComplete="off"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.angles?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              // required
              // fullWidth
              id="geography"
              label="Geography"
              name="geography"
              className="m-1"
              type="text"
              value={background.geography}
              onChange={(ev) => {
                setBackground({ ...background, geography: ev.target.value });
                setErrors({ ...errors, geography: undefined });
              }}
              error={Boolean(errors.geography)}
              autoComplete="off"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.geography?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              // required
              // fullWidth
              id="additionalBackground"
              label="Additional background"
              name="additional_background"
              type="text"
              className="m-1"
              value={background.additional_background}
              onChange={(ev) => {
                setBackground({
                  ...background,
                  additional_background: ev.target.value,
                });
                setErrors({ ...errors, additional_background: undefined });
              }}
              error={Boolean(errors.additional_background)}
              autoComplete="off"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.additional_background?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FileInputField
            maxLength={1}
            maximumSize={3e7}
            fileTypes={["pdf", "word", "txt", "pptx"]}
            onSelectFiles={async (files, setProgress) => {
              await triggerExtractFileContent({ file: files[0], setProgress });
              return "";
            }}
            uploadMessage="Upload a file to provide additional background"
          />
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className="w-fit rounded-3xl normal-case mt-2"
            disabled={isQuestionnaireLoading || isExtractingContent}
          >
            Generate Questionnaire
          </Button>
        </Box>
        {isQuestionnaireVisibile && (
          <>
            {isQuestionnaireLoading ? (
              <LinearProgress color="primary" className="h-[1px]" />
            ) : (
              <Divider className="text-black bg-black" />
            )}
            <Box>
              <DataGrid
                columns={questionnaireColumns}
                rows={questions}
                hideFooter={true}
                processRowUpdate={processRowUpdate}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
              />
            </Box>
            <Box
              className="w-full flex flex-col gap-2 transition-all"
              component="form"
              onSubmit={onCreateProject}
            >
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className="create-project-button w-fit rounded-3xl normal-case"
                disabled={isQuestionnaireLoading}
              >
                Create Project
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProjectBeingCreated}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
