import {
  Add,
  BusinessCenterOutlined,
  CalendarMonthOutlined,
  CorporateFareOutlined,
  Edit,
  ExpandMore,
  FactoryOutlined,
  PersonOutlined,
  SellOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
  Typography,
  Popover,
  Stack,
  Fab,
  TextField,
  TextFieldProps,
} from "@mui/material";
import useSWR, { mutate } from "swr";
import {
  getInterviewContent,
  deleteInterview,
  updateInterviewMetadata,
} from "../../services/interview";
import {
  CosmoInterview,
  GetAllProjectResponse,
  getAllProject,
  InterviewMetadata,
} from "../../services/project";
import { MarkdownTypography } from "../MarkdownTypography";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { generateInsightsByInterviews } from "../../services/answer";
import { useProject } from "../../hooks/useProject";
import useSWRMutation from "swr/mutation";
import { useToast } from "../../hooks/useToast";

const keys = ["content", "takeaways", "structured_summary"] as const;
const sections: { [key in KeysType]: string } = {
  content: "Transcript",
  structured_summary: "Summary by topics",
  takeaways: "Key insights",
};
type KeysType = typeof keys[number];
export const InterviewDetails = ({
  interview,
  enableExtractingInterviews = false,
  enableDeletingInterviews = false,
  enableInterviewEdit = false,
  onDelete,
  onEditInterview,
  onEditMetadata,
  onExitEditMode,
  onEnterEditMode,
}: {
  interview: CosmoInterview;
  enableExtractingInterviews?: boolean;
  enableDeletingInterviews?: boolean;
  enableInterviewEdit?: boolean;
  onDelete?: () => Promise<any> | any;
  onEditInterview?: (interview: CosmoInterview) => any;
  onEditMetadata?: () => any;
  onEnterEditMode?: () => any;
  onExitEditMode?: () => any;
}) => {
  const { data: project, mutate: mutateProject } = useProject();

  const { data: projects, mutate: mutateAllProjects } = useSWR(
    "get_all_projects",
    async () => (await getAllProject()).sort((a, b) => b.id - a.id),
    {
      throwOnError: false,
      errorRetryCount: 0,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );
  const { data: interviewContent, isLoading, mutate: mutateContent } = useSWR(
    interview?.db_id,
    (interviewId) => getInterviewContent({ interviewId }),
    {
      throwOnError: false,
      errorRetryCount: 0,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );
  const [metadata, setMetadata] = useState<InterviewMetadata>(
    interview.metadata
  );
  const [isMetaEditing, setIsMetaEditing] = useState(false);
  const {
    isMutating: isUpdatingInterviewMetadata,
    trigger: updateInterviewMetadataTrigger,
  } = useSWRMutation(
    "update_interview_metadata",
    async (
      _,
      {
        arg,
      }: {
        arg: InterviewMetadata;
      }
    ) => {
      await updateInterviewMetadata({
        ...arg,
        interviewId: parseInt(interview.db_id),
      });

      return arg;
    },
    {
      throwOnError: false,
      onSuccess(data) {
        // mutateAllInterviews(
        //   (interviews ?? []).map((val) =>
        //     val.cosmo_doc.id === data.id ? { ...val, cosmo_doc: data } : val
        //   ),
        //   { revalidate: false }
        // );
        onEditMetadata && onEditMetadata();
        setToast({
          message: "Interview has been updated successfully!",
          open: true,
          severety: "success",
        });

        setIsMetaEditing(false);
      },
      async onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );

  const [editedValues, setEditedValues] = useState<
    {
      [key in KeysType]?: string;
    }
  >({});
  const {
    isMutating: isEditingInterview,
    trigger: editInterviewTrigger,
  } = useSWRMutation(
    "edit_interview",
    async (_, { arg: key }: { arg: KeysType }) => {
      await updateInterviewMetadata({
        interviewId: Number(interview?.db_id) ?? 0,
        [key]: editedValues[key],
      });
      return key;
    },

    {
      throwOnError: false,
      onSuccess(changes) {
        setToast({
          open: true,
          severety: "success",
          message: "Interview has been updated successfuly!",
        });
        // TODO: refactor this
        changes === "content" && mutateContent(editedValues.content);
        if (changes !== "content") {
          interview!.metadata[changes!] = editedValues[changes!] ?? "";
          onEditInterview && onEditInterview(interview!);
        }
        // close edit mode after updating
        onInterviewUpdateSucess(changes);
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while updating interview!";
        setToast({
          open: true,
          severety: "error",
          message,
        });
      },
    }
  );
  const { setToast } = useToast();
  const [keyToConfirm, setKeyToConfirm] = useState<KeysType>();

  const onInterviewUpdateSucess = (key: KeysType) => {
    setEditedValues((prev) => ({ ...prev, [key]: undefined }));
    onExitEditMode && onExitEditMode();
  };

  const handleEditConfirmation = (key: KeysType) => {
    editInterviewTrigger(key);
    setKeyToConfirm(undefined);
  };

  const handleEditConfirmationClose = () => {
    setKeyToConfirm(undefined);
  };

  const {
    trigger: triggerInsightExtraction,
    isMutating: isExtracting,
  } = useSWRMutation(
    "extract_insights",
    async (_, { arg: { projectId } }: { arg: { projectId: number } }) => {
      await generateInsightsByInterviews({
        interviewIds: [parseInt(interview?.db_id ?? "0")],
        projectId,
      });
      await mutateAllProjects(
        projects!.map((val) =>
          val.id === projectId
            ? {
                ...val,
                interviews: [
                  ...val.interviews,
                  parseInt(interview?.db_id ?? "0"),
                ],
              }
            : val
        ),
        { revalidate: false }
      );
    },
    {
      throwOnError: false,
      onSuccess() {
        setToast({
          message: "Adding interview to your project have started successfuly",
          open: true,
          severety: "success",
        });
      },
      onError() {
        setToast({
          message: "Something went wrong during extracting insights",
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    isMutating: isDeletingInterview,
    trigger: deleteInterviewTrigger,
  } = useSWRMutation(
    "delete_interview",
    async (
      _,
      {
        arg: { interviewId, projectId },
      }: {
        arg: { interviewId: string | number; projectId: string | number };
      }
    ) => {
      await deleteInterview({ interviewId, projectId });

      return interviewId;
    },

    {
      throwOnError: false,
      onSuccess() {
        setToast({
          open: true,
          severety: "success",
          message: "Interview has been deleted successfuly!",
        });

        mutate(["api/interview/getall/", project?.id]);
        mutateProject();
        setAnchorEl(null);
        onDelete && onDelete();
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while deleting interview!";
        setToast({
          open: true,
          severety: "error",
          message,
        });
      },
    }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openExtractInterview = Boolean(anchorEl);
  const openDeleteInterview = Boolean(anchorEl);

  const handleExtractInterviewClick = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const hanldeProjectSelect = (project: GetAllProjectResponse[number]) => {
    triggerInsightExtraction({ projectId: project.id });
    setAnchorEl(null);
  };
  const handleDeleteInterviewClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMetaEditEnter = () => {
    setIsMetaEditing(true);
  };

  if (!interview || !projects) return null;

  const editingButtons = (
    <Box className="w-full flex justify-start gap-2">
      <Button
        size="small"
        color="secondary"
        className="rounded-2xl"
        variant="contained"
        onClick={() => {
          updateInterviewMetadataTrigger(metadata);
        }}
      >
        save changes
      </Button>
      <Button
        size="small"
        color="secondary"
        className="rounded-2xl"
        variant="outlined"
        onClick={() => {
          setMetadata(interview.metadata);
          setIsMetaEditing(false);
        }}
      >
        cancel
      </Button>
    </Box>
  );

  const actionButtons = (
    <Box className="w-full flex justify-start items-center gap-1">
      {enableExtractingInterviews && (
        <Box>
          <Button
            className="rounded-3xl whitespace-nowrap min-w-max"
            startIcon={<Add />}
            variant="contained"
            color="secondary"
            size="small"
            aria-controls={openExtractInterview ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openExtractInterview ? "true" : undefined}
            onClick={handleExtractInterviewClick}
          >
            Add to project
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={openExtractInterview}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {projects.map((project) => (
              <Tooltip
                className="z-50"
                title={
                  !project.isManager
                    ? "You don't have permission!"
                    : !!project.interviews.find(
                        (interviewId) =>
                          interviewId.toString() === interview.db_id
                      )
                    ? "The interview already exist in this project"
                    : ""
                }
                placement="left"
              >
                {/* Wrapper div */}
                <div>
                  <MenuItem
                    disabled={
                      !project.isManager ||
                      !!project.interviews.find(
                        (interviewId) =>
                          interviewId.toString() === interview.db_id
                      )
                    }
                    onClick={() => hanldeProjectSelect(project)}
                  >
                    {project.title}
                  </MenuItem>
                </div>
              </Tooltip>
            ))}
          </Menu>
        </Box>
      )}
      {enableInterviewEdit && (
        <Fab
          className=""
          style={{ transform: "scale(0.7)" }}
          size="small"
          color="secondary"
          onClick={handleMetaEditEnter}
        >
          <Edit />
        </Fab>
      )}
      {enableDeletingInterviews && (
        <Box>
          <Button
            className="rounded-3xl whitespace-nowrap min-w-max"
            variant="contained"
            color="secondary"
            size="small"
            aria-controls={openDeleteInterview ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDeleteInterview ? "true" : undefined}
            aria-describedby={"delete-interview-popup"}
            disabled={isDeletingInterview}
            onClick={handleDeleteInterviewClick}
          >
            Delete
          </Button>
          <Popover
            id={"delete-interview-popup"}
            open={openDeleteInterview}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box className="flex justify-center items-center p-6 bg-white rounded border ">
              <DeleteInterviewPopover
                onDelete={async () => {
                  // triggere deletion
                  deleteInterviewTrigger({
                    interviewId: interview.db_id,
                    projectId: project!.id,
                  });
                  // close dialog
                  setAnchorEl(null);
                }}
                onClose={() => {
                  setAnchorEl(null);
                }}
              />
            </Box>
          </Popover>
        </Box>
      )}
    </Box>
  );

  return (
    <Box className="flex flex-col gap-2 ">
      <Box className="flex flex-row flex-wrap items-center gap-2 ">
        <TextSection
          text={metadata.date}
          editable={isMetaEditing}
          onEdit={(value) => {
            setMetadata({ ...metadata, date: value });
          }}
          icon={
            <CalendarMonthOutlined className="text-black disabled:text-black " />
          }
        />
        <TextSection
          text={metadata.expert_name}
          editable={isMetaEditing}
          onEdit={(value) => {
            setMetadata({ ...metadata, expert_name: value });
          }}
          icon={<PersonOutlined className="text-black disabled:text-black " />}
        />
        <TextSection
          text={metadata.job_position}
          editable={isMetaEditing}
          onEdit={(value) => {
            setMetadata({ ...metadata, job_position: value });
          }}
          icon={
            <BusinessCenterOutlined className="text-black disabled:text-black " />
          }
        />
        <TextSection
          text={metadata.company}
          editable={isMetaEditing}
          onEdit={(value) => {
            setMetadata({ ...metadata, company: value });
          }}
          icon={
            <CorporateFareOutlined className="text-black disabled:text-black " />
          }
        />
        <TextSection
          text={metadata.industry}
          editable={isMetaEditing}
          onEdit={(value) => {
            setMetadata({ ...metadata, industry: value });
          }}
          icon={<FactoryOutlined className="text-black disabled:text-black " />}
        />
      </Box>
      <TextSection
        text={metadata.tags.join(",")}
        editable={isMetaEditing}
        onEdit={(value) => {
          setMetadata({ ...metadata, tags: value.split(",") });
        }}
        icon={<SellOutlined className="text-black disabled:text-black " />}
      />
      {isMetaEditing ? editingButtons : actionButtons}
      <Box className="w-full">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex justify-center items-center border-b-2 border-gray-400 border-solid"
          >
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextAreaSection
              text={interview.metadata.structured_summary}
              editable={enableInterviewEdit}
              onEnterEditMode={onEnterEditMode}
              onEdit={(newValue) => {
                setEditedValues((prev) => ({
                  ...prev,
                  structured_summary: newValue,
                }));
                setKeyToConfirm("structured_summary");
              }}
              onCancel={() => onExitEditMode && onExitEditMode()}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex grow justify-center items-center border-b-2 border-gray-400 border-solid"
          >
            <Typography variant="h5">Transcript</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              className={`${
                isLoading ? "flex" : "hidden"
              } w-full  justify-center`}
            >
              <CircularProgress />
            </Box>
            <TextAreaSection
              text={interviewContent ?? ""}
              preWrap={true}
              editable={enableInterviewEdit}
              onEnterEditMode={onEnterEditMode}
              onEdit={(newValue) => {
                setEditedValues((prev) => ({ ...prev, content: newValue }));
                setKeyToConfirm("content");
              }}
              onCancel={() => onExitEditMode && onExitEditMode()}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          isEditingInterview ||
          isDeletingInterview ||
          isExtracting ||
          isUpdatingInterviewMetadata
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {keyToConfirm && (
        <Dialog
          open={!!keyToConfirm}
          onClose={handleEditConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you certain of your changes?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="flex flex-col"
            >
              we have detected that you changed some text in{" "}
              {sections[keyToConfirm]}'s section
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditConfirmation(keyToConfirm)}
            >
              Update
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleEditConfirmationClose}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

const DynamicWidthTextField: React.FC<TextFieldProps> = (props) => {
  const [width, setWidth] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      // Set the initial width based on the content
      setWidth(inputRef.current.scrollWidth);
    }
  }, [props.value]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      const newWidth = inputRef.current.scrollWidth;
      setWidth(newWidth);
    }
    props.onChange && props.onChange(event);
  };

  return (
    <TextField
      {...props}
      inputRef={inputRef}
      onChange={handleInputChange}
      style={{ width: width }}
    />
  );
};
export function TextSection({
  editable,
  text,
  onEdit,
  icon,
}: {
  icon: JSX.Element;
  editable: boolean;
  text: string;
  onEdit: (newValue: string) => any;
}) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      className={`${
        editable ? "border" : "border-0"
      } border-solid border-gray-500 rounded px-2 max-w-full`}
    >
      {icon}

      <DynamicWidthTextField
        disabled={!editable}
        className="border border-gray-500 rounded py-1  disabled:text-black disabled:border-0 max-w-full"
        value={text}
        onChange={(ev) => {
          onEdit(ev.target.value);
        }}
        sx={{
          "& fieldset": { border: "none" },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            flex: 1,
            whiteSpace: "pre",
            width: "100%",
          },
          "&  .MuiInputBase-input": {
            width: "100%",
            paddingY: "1px",
          },
        }}
      />
    </Box>
  );
}

export function TextAreaSection({
  editable,
  text,
  preWrap,
  onEnterEditMode,
  onEdit,
  onCancel,
}: {
  editable: boolean;
  text: string;
  preWrap?: boolean;
  onEnterEditMode?: () => any;
  onEdit: (newValue: string) => any;
  onCancel: () => any;
}) {
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const initEditMode = () => {
    onEnterEditMode && onEnterEditMode();
    setEditMode(true);
    setEditedValue(text);
  };
  return (
    <>
      {editMode ? (
        <>
          <Box className={`flex w-full justify-end py-2 gap-2`}>
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="contained"
              onClick={() => {
                if (text !== editedValue) onEdit(editedValue);
                else onCancel();
                setEditMode(false);
              }}
            >
              save changes
            </Button>
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="outlined"
              onClick={() => {
                setEditMode(false);
                onCancel();
              }}
            >
              cancel
            </Button>
          </Box>

          <TextareaAutosize
            className="w-full border border-gray-500 rounded p-4"
            value={editedValue}
            onChange={(ev) => setEditedValue(ev.target.value)}
          />
        </>
      ) : (
        <>
          <Box
            className={`${
              editable ? "flex" : "hidden"
            } w-full justify-end py-2 gap-2`}
          >
            <Fab
              className=""
              style={{ transform: "scale(0.7)" }}
              size="small"
              color="secondary"
              onClick={initEditMode}
            >
              <Edit />
            </Fab>
          </Box>
          <MarkdownTypography
            text={text}
            className={`${
              preWrap ? "whitespace-pre-wrap" : "whitespace-normal"
            }`}
          />
        </>
      )}
    </>
  );
}
export function DeleteInterviewPopover({
  onDelete,
  onClose,
}: {
  onDelete: () => Promise<any> | any;
  onClose: () => Promise<any> | any;
}) {
  return (
    <Stack direction={"column"} spacing={3} className="px-4 min-w-[600px]">
      <MarkdownTypography
        className=""
        textAlign={"start"}
        fontSize={16}
        text={`Please confirm the deletion of this interview and all its respective answers.`}
      />
      <Stack direction={"row"} spacing={1} justifyContent={"end"}>
        <Button variant="outlined" onClick={async () => await onClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={async () => await onDelete()}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
}
