import { AutoAwesomeOutlined, Edit } from "@mui/icons-material";
import useSWRMutation from "swr/mutation";
import { Box, Button, Chip, Fab, Stack, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { useProject } from "../../hooks/useProject";
import { Question, updateQuestion } from "../../services/project";
import { MarkdownTypography } from "../MarkdownTypography";
import { useToast } from "../../hooks/useToast";

export function SummaryCell({ question }: { question: Question }) {
  const [editMode, setEditMode] = useState(false);
  const { toast, setToast } = useToast();
  const [editedInsight, setEditedInisght] = useState("");
  const { data: project, mutate: mutateProject } = useProject();
  const initEditMode = () => {
    setEditMode(true);
    setEditedInisght(question.insight);
  };
  const {
    isMutating: isUpdatingQuestion,
    trigger: updateQuestionTrigger,
  } = useSWRMutation(
    "update_question",
    async () => {
      //TODO: replace this trim with more sophisticated zod validation
      const newInsight = editedInsight.trim();
      if (question.insight === newInsight) return;

      return updateQuestion({
        questionId: question.id,
        insight: newInsight,
      });
    },

    {
      onSuccess() {
        mutateProject(
          {
            ...project,
            questions: project?.questions.map((quest) =>
              quest.id === question.id
                ? { ...question, insight: editedInsight.trim() }
                : quest
            ),
          },
          { revalidate: false }
        );
        setToast({
          open: true,
          severety: "success",
          message: "Question has been updated successfuly!",
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while updating the question!";
        setToast({
          open: true,
          severety: "error",
          message,
        });
      },
    }
  );

  if (!project) return null;
  return (
    <Box
      sx={{ border: 1, borderColor: "secondary.main" }}
      className="group relative bg-white rounded-lg p-2 max-w-lg min-w-[32rem] h-fit flex-shrink-0 flex flex-col gap-2"
    >
      <Box className="absolute top-2 right-2 flex gap-1">
        {project.isManager && !editMode && (
          <Fab
            className="opacity-0 group-hover:opacity-100 delay-500 transition-all duration-200"
            style={{ transform: "scale(0.7)" }}
            size="small"
            color="secondary"
            onClick={initEditMode}
          >
            <Edit />
          </Fab>
        )}
      </Box>
      <Box className="flex">
        <Chip
          color="secondary"
          icon={<AutoAwesomeOutlined />}
          label={"AI Summary"}
          variant="outlined"
        />
      </Box>
      {editMode ? (
        <>
          <TextareaAutosize
            className="w-full  border-gray-300 outline-none border rounded-2xl p-2 overflow-hidden whitespace-pre-line text-start"
            disabled={isUpdatingQuestion}
            value={editedInsight}
            onChange={(ev) => {
              setEditedInisght(ev.target.value);
            }}
          />
          <Stack
            direction={"row"}
            gap={1}
            alignItems="end"
            justifyContent={"end"}
          >
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="contained"
              onClick={async () => {
                await updateQuestionTrigger();
                setEditMode(false);
              }}
            >
              save changes
            </Button>
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="outlined"
              onClick={() => setEditMode(false)}
            >
              cancel
            </Button>
          </Stack>
        </>
      ) : (
        <MarkdownTypography text={question.insight} className="text-start " />
      )}
    </Box>
  );
}
