import { verifyPayment } from "../services/payment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useSWRMutation from "swr/mutation";
import { useAuthContext } from "../hooks/useAuth";
import { Box, CircularProgress } from "@mui/material";

export function SuccessPage() {
  const [searchParams] = useSearchParams();
  const { refresh } = useAuthContext();
  const navigate = useNavigate();
  const { isMutating, trigger } = useSWRMutation(
    "success_session",
    async (_, { arg: { sessionId } }: { arg: { sessionId: string } }) => {
      await verifyPayment({ sessionId });
      await refresh();
    },
    {
      onSuccess(data, key, config) {
        setMessage("Redericting...");
        navigate("/");
      },
    }
  );
  const [message, setMessage] = useState(
    "Loading! ... we are verifying your payment."
  );

  useEffect(() => {
    const sessionId = searchParams.get("session_id") ?? "";
    trigger({ sessionId });
  }, []);
  return (
    <Box className="w-full h-screen gap-4 flex flex-col justify-center items-center">
      <span>{message}</span>
      {isMutating && <CircularProgress className="" color="primary" />}
    </Box>
  );
}
