import { AxiosProgressEvent } from "axios";
import { axios } from "../utils/axios";
import { User } from "../hooks/useAuth";

export type Project = {
  id: number;
  questions: Question[];
  title: string;
  description: string;
  industry: string;
  angles: string;
  additional_background: string;
  geography: string;
  created_at: Date;
  updated_at: Date;
  organization: number;
  suggested_intreviews: ProjectInterviewSuggestion[];
  abbreviations: string[];
  companies: string[];
};
export type ProjectInterviewSuggestion = {
  id: number;
  project: number;
  score: number;
  // interview coming from cosmos db directly
  interview: CosmoInterview;
};
export type Question = {
  id: number;
  question: string;
  angle: string;
  order: number;
  insight: string;
  created_at: Date;
  updated_at: Date;
  project: number;
  answers: AnswerWithInterview[];
};
export type InterviewType = "PB" | "OR" | "PR";
export type AnswerWithInterview = Omit<Answer, "interview"> & {
  interview: Interview;
};
export type Interview = {
  id: number;
  cosmo_doc: CosmoInterview;
  title: string;
  type: InterviewType;
  tags: any[];
  created_at: string;
  updated_at: string;
  project: number;
};

export type Answer = {
  id: number;
  answer: string;
  quotes: string[];
  isEdited: boolean;
  created_at: string;
  updated_at: string;
  question: number;
  interview: number;
};
export type CosmoInterview = {
  id: string;
  organizationId: string;
  db_id: string;
  metadata: InterviewMetadata;
};

export type InterviewMetadata = {
  filename: string;
  company: string;
  job_position: string;
  expert_name: string;
  industry: string;
  date: string;
  tags: string[];
  summary: string;
  takeaways: string;
  structured_summary: string;
  figure_table: FigureTable;
  companies: Companies;
};

export type FigureTable = {
  number: string[];
  meaning: string[];
  quote: string[];
};

export type Companies = {
  Company: any[];
  Description: any[];
};

export type Questionnaire = {
  questions: { angle: string; question: string }[];
};
export async function getQuestionnaire({
  background,
  onDownloadProgress,
}: {
  background: ProjectBackground & { additional_file_background?: string };
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
}): Promise<Questionnaire> {
  return (
    await axios.post("/api/project/getQuestionnaire/", background, {
      onDownloadProgress,
    })
  ).data;
}

export type ProjectBackground = {
  // NOTE: Title will be generated when project is created
  // title: string;
  industry: string;
  angles: string;
  // backend allows empty string or undefiend
  // but for simplicity sake we force them to be string
  additional_background: string;
  geography: string;
};

export async function getBackgroundInfo(params: {
  message: string;
}): Promise<ProjectBackground> {
  return (await axios.post("/api/project/getBackgroundInfo/", { ...params }))
    .data;
}

export type ProjectCreationResponse = {
  id: number;
  title: string;
  description: string;
  industry: string;
  angles: string;
  additional_background: string;
  geography: string;
  created_at: string;
  updated_at: string;
  organization: number;
};
export type PartialQuestion = {
  angle: string;
  question: string;
};

export async function createProject(
  params: ProjectBackground & { questions: PartialQuestion[] }
): Promise<ProjectCreationResponse> {
  return (await axios.post("/api/project/create/", params)).data;
}

export type GetAllProjectResponse = (ProjectCreationResponse & {
  interviews: number[];
  isManager: boolean;
})[];
export async function getAllProject(): Promise<GetAllProjectResponse> {
  return (await axios.get("/api/project/getAll/")).data;
}

export type GetProjectById = Project & { isManager: boolean };

export async function getProjectById(id: string): Promise<GetProjectById> {
  return (await axios.get("/api/project/", { params: { id } })).data;
}

export async function addQuestionToProject({
  angle,
  projectId,
  question,
}: {
  projectId: number;
  angle: string;
  question: string;
}): Promise<void> {
  return (
    await axios.post("/api/project/addQuestion/", {
      angle,
      projectId,
      question,
    })
  ).data;
}
export async function updateQuestion({
  questionId,
  angle,
  question,
  insight,
}: {
  questionId: number;
  question?: string;
  angle?: string;
  insight?: string;
}): Promise<string> {
  return (
    await axios.put("/api/project/updateQuestion/", {
      questionId,
      angle,
      question,
      insight,
    })
  ).data;
}
export async function deleteQuestion({
  questionId,
}: {
  questionId: string | number;
}): Promise<string> {
  return (
    await axios.delete("/api/project/deleteQuestion/", {
      params: { questionId },
    })
  ).data;
}

export async function getAllUsersPerProject({
  projectId,
}: {
  projectId: string;
}): Promise<{ managers: User[]; users: User[] }> {
  return (
    await axios.get("/api/project/getAllUsers/", {
      params: {
        projectId,
      },
    })
  ).data;
}

export async function addManager({
  projectId,
  userId,
}: {
  projectId: number;
  userId: number;
}): Promise<string> {
  return (await axios.put(`/api/project/addManager/`, { projectId, userId }))
    .data;
}
export async function addUser({
  projectId,
  userId,
}: {
  projectId: number;
  userId: number;
}): Promise<string> {
  return (await axios.put(`/api/project/addUser/`, { projectId, userId })).data;
}

export async function removeUserFromProject({
  projectId,
  userId,
}: {
  projectId: number;
  userId: number;
}): Promise<string> {
  return (await axios.put(`/api/project/removeUser/`, { projectId, userId }))
    .data;
}

export async function getNotInvolvedUsers({
  projectId,
}: {
  projectId: string;
}): Promise<User[]> {
  return (
    await axios.get("/api/project/getNotInvolvedUsers/", {
      params: {
        projectId,
      },
    })
  ).data;
}

export async function inviteUsersToProject({
  emails,
  projectId,
  isAdmin,
}: {
  projectId: number;
  emails: string[];
  isAdmin?: boolean;
}): Promise<void> {
  return axios.put("/api/project/inviteUsers/", {
    projectId,
    emails,
    isAdmin,
  });
}

export async function updateInterviewVisibility({
  projectId,
  visibility,
}: {
  projectId: number;
  visibility: InterviewType;
}) {
  return axios.put(`/api/project/updateInterviewsVisibility/`, {
    projectId,
    visibility,
  });
}

export async function exportProjectToExcel({ project }: { project: Project }) {
  const response = await axios.get<Blob>("/api/project/exportExcel/", {
    params: {
      id: project.id,
    },
    responseType: "blob",
  });

  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;

  link.setAttribute(
    "download",
    `${new Date()
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "")}_${project.title.replace(
      " ",
      "_"
    )}_Interview_Insights.xlsx`
  ); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export async function extractFileContent({
  formData,
  onUploadProgress,
}: {
  formData: FormData;
  onUploadProgress: (params: AxiosProgressEvent) => any;
}): Promise<string> {
  return (
    await axios.post("/api/project/extractFileContent/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
  ).data;
}
export async function updateProject(params: {
  projectId: number;
  companies?: string[];
  abbreviations?: string[];
}): Promise<string> {
  return (await axios.put("/api/project/update/", params)).data;
}
