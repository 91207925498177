import { useParams } from "react-router-dom";
import { getProjectById } from "../services/project";
import useSWR from "swr";

export function useProject() {
  const { id } = useParams();
  return useSWR(
    () => id,
    (key) => getProjectById(key),
    {
      throwOnError: false,
      errorRetryCount: 0,
    //   revalidateIfStale: false,
    //   revalidateOnFocus: true,
    //   revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );
}
