import { Add, Cached, Delete, Edit, PersonOutline } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Fab,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import { useProject } from "../../hooks/useProject";
import { AnswerWithInterview } from "../../services/project";
import { MarkdownTypography } from "../MarkdownTypography";
import useSWRMutation from "swr/mutation";
import { regenerateAnswer, updateAnswer } from "../../services/answer";
import { isEqual } from "lodash";
import { ConfirmationDialog } from "../project/NewQuestionDialog";
import { useToast } from "../../hooks/useToast";

export function AnswerCell({
  answer,
  displayQuotes = true,
}: {
  answer: AnswerWithInterview;
  displayQuotes?: boolean;
}) {
  const { setToast } = useToast();

  const [editMode, setEditMode] = useState(false);
  const [editedAnswer, setEditedAnswer] = useState("");
  const { data: project, mutate: mutateProject } = useProject();
  const [isAddingQuote, setIsAddingQuote] = useState(false);
  const initEditMode = () => {
    setEditMode(true);
    setEditedAnswer(answer.answer);
  };
  const {
    isMutating: isUpdatingAnswer,
    trigger: updateAnswerTrigger,
  } = useSWRMutation(
    "update_answer",
    async (
      _,
      {
        arg: { answer: editedAnswer, quotes },
      }: { arg: { answer?: string; quotes?: string[] } }
    ) => {
      // TODO: make this trim into more sphisticated zod validation
      const newAnswerText = editedAnswer?.trim();
      await updateAnswer({
        answerId: answer.id,
        ...(newAnswerText &&
          answer.answer !== newAnswerText && { answer: newAnswerText }),
        ...(!isEqual(quotes, answer.quotes) && { quotes: quotes }),
      });
      await mutateProject();
    },

    {
      throwOnError: false,
      onSuccess() {
        setToast({
          open: true,
          severety: "success",
          message: "answer has been updated successfuly!",
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while updating answer!";
        setToast({
          open: true,
          severety: "error",
          message,
        });
      },
    }
  );
  const {
    isMutating: isRegeneratingAnswer,
    trigger: regenerateAnswerTrigger,
  } = useSWRMutation(
    "regenerate_answer",
    async () => {
      return regenerateAnswer({ answerId: answer.id });
    },

    {
      throwOnError: false,
      onSuccess() {
        mutateProject();
        setToast({
          open: true,
          severety: "success",
          message: "answer has been updated successfuly!",
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while updating answer!";
        setToast({
          open: true,
          severety: "error",
          message,
        });
      },
    }
  );
  if (!project) return null;

  return (
    <Box
      sx={{ border: 1, borderColor: "gray" }}
      className="bg-white rounded-lg p-2 min-w-[512px] h-fit max-w-lg flex flex-col gap-2 flex-shrink-0"
    >
      <Box className="group/answer relative flex flex-col gap-2 ">
        {project.isManager && !editMode && (
          <Box className="flex gap-0 absolute top-1 right-2">
            <Fab
              className="opacity-0 group-hover/answer:opacity-100 delay-500 transition-all duration-200 "
              style={{ transform: "scale(0.7)" }}
              size="small"
              color="secondary"
              onClick={initEditMode}
            >
              <Edit />
            </Fab>
            <Tooltip title="regenerate">
              <Fab
                className="opacity-0 group-hover/answer:opacity-100 delay-500 transition-all duration-200 "
                style={{ transform: "scale(0.7)" }}
                size="small"
                color="secondary"
                onClick={() => regenerateAnswerTrigger()}
              >
                <Cached />
              </Fab>
            </Tooltip>
          </Box>
        )}
        <Box className="flex">
          <Chip
            icon={<PersonOutline />}
            label={`${answer.interview.cosmo_doc.metadata.job_position} @${answer.interview.cosmo_doc.metadata.company}`}
            variant="outlined"
          />
        </Box>
        {editMode ? (
          <>
            <TextareaAutosize
              className="w-full min-w-full  border-gray-300 outline-none border rounded-2xl p-2 overflow-hidden whitespace-pre-line text-start"
              disabled={isUpdatingAnswer}
              value={editedAnswer}
              onChange={(ev) => {
                setEditedAnswer(ev.target.value);
              }}
            />
            <Box className="flex gap-2">
              <Button
                size="small"
                color="secondary"
                className="rounded-2xl"
                variant="contained"
                onClick={async () => {
                  await updateAnswerTrigger({ answer: editedAnswer });
                  setEditMode(false);
                }}
              >
                save changes
              </Button>
              <Button
                size="small"
                color="secondary"
                className="rounded-2xl"
                variant="outlined"
                onClick={() => setEditMode(false)}
              >
                cancel
              </Button>
            </Box>
          </>
        ) : (
          <MarkdownTypography text={answer.answer} className="text-start " />
        )}
      </Box>
      {displayQuotes && (
        <Box className="group/quotes relative flex flex-col gap-2">
          <Fab
            className="opacity-0 group-hover/quotes:opacity-100 delay-500 transition-all duration-200 absolute top-1 right-2"
            style={{ transform: "scale(0.7)" }}
            size="small"
            color="secondary"
            onClick={() => {
              setIsAddingQuote(true);
            }}
          >
            <Add />
          </Fab>
          <Divider />
          <Typography
            className="pb-2"
            textAlign={"start"}
            color={"grey"}
            fontWeight={700}
          >
            Quotes
          </Typography>
          {isAddingQuote && (
            <QuoteCell
              quote=""
              edit={true}
              onDelete={() => setIsAddingQuote(false)}
              onEdit={(newQuote) => {
                if (!newQuote.trim()) {
                  setIsAddingQuote(false);
                  return;
                }
                updateAnswerTrigger({
                  quotes: [newQuote.trim(), ...answer.quotes],
                });
                setIsAddingQuote(false);
              }}
              onCancelChange={() => setIsAddingQuote(false)}
            />
          )}
          {answer.quotes.map((quote, index) => (
            <QuoteCell
              key={`quotes-${answer.id}-${index}`}
              quote={quote}
              onEdit={(newQuote) => {
                if (!newQuote.trim()) return;
                updateAnswerTrigger({
                  quotes: answer.quotes.map((val, i) =>
                    i !== index ? val : newQuote.trim()
                  ),
                });
              }}
              onDelete={() => {
                updateAnswerTrigger({
                  quotes: answer.quotes.filter((_, i) => i !== index),
                });
              }}
            />
          ))}
        </Box>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUpdatingAnswer || isRegeneratingAnswer}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

const MAX_QUOTE_LENGTH = 250;
export function QuoteCell({
  quote,
  edit = false,
  onEdit,
  onDelete,
  onCancelChange,
}: {
  quote: string;
  edit?: boolean;
  onEdit?: (newValue: string) => any;
  onDelete?: () => any;
  onCancelChange?: () => any;
}) {
  const { data: project } = useProject();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [editMode, setEditMode] = useState(edit);
  const [editedQuote, setEditedQuote] = useState("");
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = useCallback(() => setShowMore(!showMore), [showMore]);
  const isExpandable = quote.length > MAX_QUOTE_LENGTH;
  if (!project) return null;
  const initEditMode = () => {
    setEditMode(true);
    setEditedQuote(quote);
  };
  return (
    <Box className="group">
      {editMode ? (
        <>
          <TextareaAutosize
            className="w-full  border-gray-300 outline-none border rounded-2xl p-2 overflow-hidden whitespace-pre-line text-start"
            // disabled={isUpdatingAnswer}
            value={editedQuote}
            onChange={(ev) => {
              setEditedQuote(ev.target.value);
            }}
          />
          <Box className="flex gap-2">
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="contained"
              onClick={async () => {
                onEdit && (await onEdit(editedQuote));
                setEditMode(false);
              }}
            >
              save changes
            </Button>
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="outlined"
              onClick={() => {
                setEditMode(false);
                onCancelChange && onCancelChange();
              }}
            >
              cancel
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography
            fontStyle={"italic"}
            color={"grey"}
            textAlign={"start"}
            whiteSpace="pre-line"
          >
            "
            {!isExpandable || showMore
              ? quote
              : `${quote.substring(0, 250)}...`}
            "
            <Button
              sx={{ color: "grey" }}
              className={`${
                isExpandable ? "visible" : "invisible"
              } normal-case  `}
              onClick={toggleShowMore}
            >
              {showMore ? "Show less" : "Show more"}
            </Button>
          </Typography>
          {project.isManager && (
            <Stack direction={"row"} alignItems={"start"}>
              <Fab
                className="opacity-0 group-hover:opacity-100 delay-500 transition-all duration-200"
                style={{ transform: "scale(0.7)" }}
                size="small"
                color="secondary"
                onClick={initEditMode}
              >
                <Edit />
              </Fab>
              <Fab
                className="opacity-0 group-hover:opacity-100 delay-500 transition-all duration-200"
                style={{ transform: "scale(0.7)" }}
                size="small"
                color="secondary"
                onClick={() => setIsConfirmDeleteOpen(true)}
              >
                <Delete />
              </Fab>
            </Stack>
          )}
        </>
      )}
      <Dialog
        open={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
      >
        <DialogTitle>Delete Quote</DialogTitle>
        <Box className="flex justify-center items-center p-6 bg-white rounded border ">
          <ConfirmationDialog
            message="Are you sure you want to delete this quote?"
            onConfirm={async () => {
              // triggere deletion
              onDelete && onDelete();
              // close dialog
              setIsConfirmDeleteOpen(false);
            }}
            onCancel={() => {
              setIsConfirmDeleteOpen(false);
            }}
          />
        </Box>
      </Dialog>
    </Box>
  );
}
